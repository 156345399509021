'use client';

import { Button } from '@/components/Atoms/Button';
import { RightArrow } from '@/components/Atoms/Icons';
import { CameraIcon } from '@/components/Atoms/Icons/CameraIcon';
import { FloorplanIcon } from '@/components/Atoms/Icons/FloorplanIcon';
import LeftArrow from '@/components/Atoms/Icons/LeftArrow';
import ImageAsset, { IMAGE_ASSET_FRAGMENT } from '@/components/Atoms/ImageAsset';
import { withFragmentArray } from '@liquorice/gql-utils';
import { useEffect, useRef, useState } from 'react';
import { FullscreenScrollingImageBlock } from './FullscreenScrollingImageBlock';

export interface ScrollingImageBlockProps {
  controls?: 'overlay' | 'bottom';
  caption?: string;
}

export const ScrollingImageBlock = withFragmentArray(
  IMAGE_ASSET_FRAGMENT,
  (images, { controls = 'bottom', caption }: ScrollingImageBlockProps) => {
    const carouselRef = useRef<HTMLUListElement>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showFloorplan, setShowFloorplan] = useState(false);

    const goToNextSlide = () => {
      if (carouselRef.current) {
        const nextIndex = (currentIndex + 1) % images.length;
        carouselRef.current.scrollLeft = carouselRef.current.clientWidth * nextIndex;
      }
    };

    const goToPreviousSlide = () => {
      if (carouselRef.current) {
        const previousIndex = (currentIndex - 1 + images.length) % images.length;
        carouselRef.current.scrollLeft = carouselRef.current.clientWidth * previousIndex;
      }
    };

    // add an event listener to the carouselRef to update the currentIndex when the user scrolls
    useEffect(() => {
      const currentRef = carouselRef.current;

      if (!currentRef) return;

      const handleScroll = () => {
        const index = Math.round(currentRef.scrollLeft / currentRef.clientWidth);
        setCurrentIndex(index);
      };
      currentRef.addEventListener('scroll', handleScroll);
      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }, []);

    if (images?.length === 0) return null;

    return (
      <div>
        <div className={`relative`}>
          <div className={`${controls === 'overlay' ? 'aspect-h-10 aspect-w-16' : ''}`}>
            <ul
              ref={carouselRef}
              className={`no-scrollbar flex w-full snap-x snap-mandatory overflow-x-scroll scroll-smooth ${controls === 'overlay' ? 'rounded-3xl' : 'rounded-3xl md:rounded-[32px] lg:rounded-5xl'}`}>
              {images.map((image, index) => (
                <li key={index} className={`w-full flex-shrink-0 snap-center snap-always`}>
                  <ImageAsset data={image} />
                </li>
              ))}
            </ul>
          </div>
          {controls === 'overlay' && (
            <>
              <div className="absolute left-4 top-1/2 -translate-y-1/2 transform">
                <Button
                  aria-label="Previous image"
                  element="button"
                  size="circleResponsive"
                  color="white"
                  onClick={goToPreviousSlide}>
                  <LeftArrow className="stroke-bgPrimary" />
                </Button>
              </div>
              <div className="absolute right-4 top-1/2 -translate-y-1/2 transform">
                <Button
                  aria-label="Next image"
                  element="button"
                  size="circleResponsive"
                  color="white"
                  onClick={goToNextSlide}>
                  <RightArrow className="stroke-bgPrimary" />
                </Button>
              </div>
              <div className="absolute bottom-4 right-4 block md:hidden">
                <Button
                  aria-label="Show all images"
                  element="button"
                  size="small"
                  color="white"
                  onClick={() => setIsFullscreen(true)}>
                  <CameraIcon />
                  &ensp;({images.length})
                </Button>
              </div>
            </>
          )}
          {controls === 'bottom' && (
            <div className="mt-5 flex flex-col gap-4 lg:flex-row lg:justify-between">
              <p>{caption}</p>
              <div className="text-tBase flex items-center gap-4">
                <Button
                  aria-label="Previous image"
                  element="button"
                  size="circleSmall"
                  color="whiteOutlined"
                  onClick={goToPreviousSlide}>
                  <LeftArrow className="stroke-bgPrimary" />
                </Button>
                <h6>
                  {currentIndex + 1}/{images.length}
                </h6>
                <Button
                  aria-label="Next image"
                  element="button"
                  size="circleSmall"
                  color="whiteOutlined"
                  onClick={goToNextSlide}>
                  <RightArrow className="stroke-bgPrimary" />
                </Button>
              </div>
            </div>
          )}
        </div>
        {controls === 'overlay' && (
          <>
            <div className="mt-5 hidden w-full justify-end gap-5 md:flex">
              <Button
                color="white"
                element={'button'}
                size="medium"
                className="mt-4"
                onClick={() => setIsFullscreen(true)}>
                <CameraIcon /> <span className="ml-2">View all images</span>
              </Button>
              <Button
                color="white"
                element={'button'}
                size="medium"
                className="mt-4"
                onClick={() => {
                  setShowFloorplan(true);
                  setIsFullscreen(true);
                }}>
                <FloorplanIcon /> <span className="ml-2">Floorplan</span>
              </Button>
            </div>
            <FullscreenScrollingImageBlock
              images={images}
              openFullscreen={isFullscreen}
              showFloorplan={showFloorplan}
              setShowFloorplan={setShowFloorplan}
              CloseFullscreen={() => setIsFullscreen(false)}
            />
          </>
        )}
      </div>
    );
  },
);
