import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

const FluidContainerStyles = cva(
  'mx-auto px-4 md:px-6 lg:px-8', // Base styles
  {
    variants: {
      width: {
        article: 'max-w-4xl',
        full: 'max-w-screen-2xl',
      },
      bottomPadding: {
        true: 'lg:pb-20 md:pb-16 pb-10',
        false: '',
      },
      topPadding: {
        true: 'pt-16',
        false: '',
      },
    },
    defaultVariants: {
      width: 'full', // Default size if none is provided
      bottomPadding: true,
    },
  },
);

export const FluidContainer = ({
  children,
  id,
  width = 'full',
  bottomPadding = true,
  topPadding = false,
}: {
  children: ReactNode;
  id?: string;
  width?: 'article' | 'full';
  bottomPadding?: boolean;
  topPadding?: boolean;
}) => {
  return (
    <section id={id} className={FluidContainerStyles({ width, bottomPadding, topPadding })}>
      {children}
    </section>
  );
};
